import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { datadogLogs as datadog } from '@datadog/browser-logs';

declare var angular: angular.IAngularStatic;

@Injectable({
    providedIn: 'root',
})
export class LoggerService {
    private initialized = false;
    private Env: string = '';
    constructor() {
        this.setEnv();
        datadog.init({
            clientToken: 'pube964d07d9383d62e87db0843c2a4916a',
            service: 'cad-ui',
            forwardErrorsToLogs: true,
            sessionSampleRate: 100,
            env: this.Env
        });
        this.initialized = true;
    }
    setEnv() {
      const location = window.location.host;
      switch (location) {
        case 'ui.dev.tap.cts.comcast.net':
            this.Env = 'dev';
            break;
        case 'localhost:3001':
          this.Env = 'dev';
          break;
        case 'ui.test.tap.cts.comcast.net':
            this.Env = 'test';
            break
        case 'login.int.tap.cts.comcast.net':
            this.Env = 'int';
            break;
        case 'login.preprod.tap.cts.comcast.net':
            this.Env = 'preprod';
            break;
        default:
          this.Env = 'prod';
      }
    }
    public debug(message: string, context?: { [x: string]: any }): void {
        if (this.initialized) {
          datadog.logger.debug(message, context);
        }
    }
    
    public info(message: string, context?: { [x: string]: any }): void {
      if (this.initialized) {
        datadog.logger.info(message, context);
      }
    }
    
    public warn(message: string, context?: { [x: string]: any }): void {
      if (this.initialized) {
        datadog.logger.warn(message, context);
      }
    }
    
    public error(message: string, context?: { [x: string]: any }): void {
      if (this.initialized) {
        datadog.logger.error(message, context);
      }
    }
}   
angular.module('comcast.common.communication').factory('loggerService', downgradeInjectable(LoggerService));
